import React, { useState, useEffect } from 'react';
import { Breadcrumb } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import HairiButton from "../component/hairiButton";
import dow from '../image/dow.png'
import robosim from '../image/robosim.png'
import { Link } from 'react-router-dom';


const InfoCard = ({ title, version, buttonActions, imageSrc, backgroundColor }) => (
    <div style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 20,
    }}>
        <div style={{
            width: '1200px',
            minHeight: '230px',
            marginTop: 80,
            backgroundColor: "#F2FBFF",
            display: "flex",
            alignItems: "center",
            justifyContent: 'space-between'
        }}>
            <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: "40px", width: '150px', height: "150px", backgroundColor: backgroundColor, borderRadius: "10px" }}>
                    <img src={imageSrc} alt="" style={{ width: '140px', height: "140px", }} />
                </div>
                <div style={{ marginLeft: "20px" }}>
                    <span style={{ fontSize: '32px', width: "200px", height: "20px", }}>{title}</span>
                    <br />
                    {version && <span>{version}</span>}
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '400px', marginRight: "40px" }}>
                {buttonActions.map((action, index) => (
                    <div key={index} style={{ marginRight: "20px", flex: 1 }}>
                        <HairiButton onClick={action.onClick} buttonText={action.text} />
                        {action.downloadLink && (
                            <div style={{ marginTop: "10px", textAlign: 'center' }}>
                                <a href={action.downloadLink} download>{action.downloadText}</a>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    </div>
);

const Download = () => {
    const [downloadLinks, setDownloadLinks] = useState({
        win32: "",
        linux: "",
        mac: ""
    });
    useEffect(() => {
        // 从接口获取下载地址
        fetch('https://oss.zmrobo.com/mlblock/download_mlblock_info.json')
            .then(response => response.json())
            .then(data => setDownloadLinks(data))
            .catch(error => console.error('Error fetching download links:', error));
    }, []);
    const items = [
        {
            title: (
                <>
                    <LeftOutlined />
                    <Link to="/">首页</Link>
                </>
            ),
        },
    ]

    const createDownloadLink = (url, filename) => {
        const link = document.createElement('a');
        link.href = url;
        link.rel = "noreferrer";
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    // const downloadWinAction = () => createDownloadLink("https://oss.zmrobo.com/mlblock/mlblock_1.0.32_win.exe", "mlblock_1.0.32_win.exe");
    // const showModal = () => createDownloadLink("https://oss.zmrobo.com/mlblock/linux/mlblock_1.0.32_amd64.deb", "mlblock_1.0.32_amd64.deb");
    // const downloadMacAction = () => createDownloadLink("https://oss.zmrobo.com/mlblock/mac/mlblock-1.0.32.dmg", "mlblock-1.0.32.dmg");

    const downloadWinAction = () => createDownloadLink(downloadLinks.win32, "mlblock_win.exe");
    const showModal = () => createDownloadLink(downloadLinks.linux, "mlblock_linux.deb");
    const downloadMacAction = () => createDownloadLink(downloadLinks.mac, "mlblock_mac.dmg");

    const downloadWinActionFz = () => createDownloadLink("/ML虚拟仿真平台.zip", "ML虚拟仿真平台");

    return (
        <>
            <div
                style={{
                    width: '100%',
                    minWidth: "1200px",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 'auto',
                }}>
                <div
                    style={{
                        marginTop: "20px",
                        width: '1200px',
                    }}
                >
                    <Breadcrumb items={items} />
                </div>
            </div>
            <InfoCard
                title="ML Block"
                version="v1.0.32"
                imageSrc={dow}
                backgroundColor="#43C5FF"
                buttonActions={[
                    { onClick: downloadWinAction, text: "Windows", downloadLink: "/启智板USB驱动.zip", downloadText: "win7驱动下载" },
                    { onClick: showModal, text: "统信UOS" },
                    { onClick: downloadMacAction, text: "Mac" }
                ]}
            />
            <InfoCard
                title="ML 仿真软件"
                imageSrc={robosim}
                backgroundColor="rgb(255, 255, 255)"
                buttonActions={[
                    { onClick: downloadWinActionFz, text: "Windows" }
                ]}
            />

        </>
    );
};

export default Download;